.UserAvatar .p-badge {
  background: #1757a2 !important;
  min-width: 0.7rem !important;
  height: 0.7rem !important;
}

.UserAvatar .p-overlay-badge .p-badge {
  top: 43px !important;
  right: 8px;
  transform: translate(50%, -50%);
  transform-origin: 100% 0;
  margin: 0;
}
