.Users .p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: none;
  border-width: 0 0 1px 0;
  padding: 0.5rem 1rem;
}

.Users .p-paginator .p-paginator-first {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  box-shadow: 0px 4px 8px rgba(230, 230, 230, 0.5);
}

.Users .p-paginator .p-paginator-prev {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  box-shadow: 0px 4px 8px rgba(230, 230, 230, 0.5);
}

.Users .p-paginator .p-paginator-next {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  box-shadow: 0px 4px 8px rgba(230, 230, 230, 0.5);
}

.Users .p-paginator .p-paginator-last {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  box-shadow: 0px 4px 8px rgba(230, 230, 230, 0.5);
}

.Users .p-paginator {
  background: #fbfbfb;
  color: #000000;
  border: solid #e9ecef;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 6px;
}

.Users .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  /* background: #ffffff;
  border-color: #eef2ff; */
  color: #000000;
  background: #99c7eb;
  /* Logo colours */

  border: 1px solid #1757a2;
  /* Card Shadow */

  box-shadow: 0px 4px 8px rgba(230, 230, 230, 0.5);
}

.Users .p-paginator .p-paginator-pages .p-paginator-page {
  background: #ffffff;
  border: 0 none;
  color: #000000;
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}
