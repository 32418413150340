.otpContainer {
  margin: 5% auto;
}

.otpInput {
  width: 4rem !important;
  height: 3rem;
  margin: 0 0.5rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: #515151;
  box-shadow: 0px 5.07837px 5.07837px rgba(0, 0, 0, 0.55);
  font-style: normal;
  font-weight: 600;
  font-size: 2, 1875rem;
  line-height: 25px;
}