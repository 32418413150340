.Account-Login-Container .ant-input:focus {
  border: 0 !important;
  /* border-color: #57a8e9 !important; */
  border-bottom: 1px solid red;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
}

.ant-form-item-explain-error {
  text-align: start;
}
