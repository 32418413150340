.sidermain-logout {
    position: absolute !important;
    bottom: 2% !important;
    padding-inline: 0;
    padding-bottom: 16px;
    align-items: center;
    background-color: var(--color-primary-light) !important;
}

.sidermain-logouthover {
    background-color: white !important;
}


.siderMainMenu .ant-menu-item-selected {
    background-color: transparent !important;
    border: 1px solid #ffffff !important;
}