.responderAnalytics .p-multiselect .p-multiselect-label.p-placeholder {
  color: #ffffff !important;
}

.responderAnalytics .p-icon {
  color: #ffffff !important;
}

.responderAnalytics .anticon svg {
  color: #ffffff !important;
}

.responderAnalytics.ant-picker-input::placeholder {
  color: #ffffff !important;
}

.responderAnalytics .white-placeholder::placeholder {
  color: #ffffff !important;
}
