.messagePanel .p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.45);
  background: #fbfbfb;
  padding: 0.75rem 0.75rem;
  border: 0.3px solid #9b9b9b !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 10px !important;
}

.messagePanel .p-inputtext:enabled:focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
  border: 1px solid #9b9b9b !important;
}

.messagePanel .p-input-icon-left > .p-inputtext {
  padding-left: 2.7rem;
}

.messagePanel .p-input-icon-left,
.p-input-icon-right {
  position: relative;
  display: inline-block;
}

.messagePanel .p-badge {
  background: #1757a2 !important;
  min-width: 0.7rem !important;
  height: 0.7rem !important;
}

.messagePanel .p-overlay-badge .p-badge {
  top: 43px !important;
  right: 8px;
  transform: translate(50%, -50%);
  transform-origin: 100% 0;
  margin: 0;
}

.messagePanelDate .p-badge {
  background: #1757a2 !important;
  color: "#99C7EB" !important;
  min-width: 1.2rem !important;
  height: 1.2rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
