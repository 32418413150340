.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fdfeff;
  pointer-events: auto;
  /* border-radius: 10px 0px 0px 0px; */
  border-top-left-radius: 16px;
  border-left: 1px solid #cdd5de;
}

.ant-drawer-content-wrapper {
  padding-top: 40px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none !important;
}

.ant-drawer-right>.ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  /* box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05); */
}

.ant-drawer .ant-drawer-mask {
  position: absolute;
  inset: 0;
  z-index: 1000;
  background: rgba(153, 199, 235, 0.5);
  pointer-events: auto;
}



.ant-btn.custom-button-secondary-hover:hover {
  /*.ant-btn only needed if .ant-btn:hover specified elsewhere*/
  background-color: rgba(153, 199, 235, 0.5);
}

.ant-btn.custom-button-pause-hover:hover {
  /*.ant-btn only needed if .ant-btn:hover specified elsewhere*/
  background-color: #FDB022;
}